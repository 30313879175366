import { Box, Button, Divider, Flex, IconButton, Stack, Step, StepDescription, StepIndicator, StepSeparator, StepStatus, StepTitle, Stepper, Text } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { getProperty } from "dot-prop";
import { Icon } from "@iconify/react";
import { convertPrice, convertToCurrency, getLabel, handleEmptyData, isInvalidString, removeUnderscore, truncateString } from "@utils";
import { ICaseResponseObject } from "@config/types";
import { TPropertyType } from "store/CreateCaseStore";
import dayjs from "dayjs";
import { memo, useEffect } from "react";
import { SERVICE_TYPE_MAP, ambulanceMapper } from "@config/constant";
import Rating from "@components/molecules/Rating";
import { redEvent } from "lib/red-hooks";

const CrmCard = (props: ICaseResponseObject) => {
  const getValue = (key: TPropertyType) => getProperty(props, key);
  const selectedCard = getLabel(props.bookingType, props.orderStatus, props.orderId || "");
  const navigate = useNavigate();

  const handleClick = () => {
    if (selectedCard?.to) {
      navigate(selectedCard?.to);

      redEvent.push({
        key: "CASE_CARD_CLICKED",
        action: {
          orderId: getValue("orderId"),
          generatedBy: getValue("generatedBy"),
        },
      });
    }
  };

  const subtype = getValue("entityRequired[0].subtype");

  const handleSelect = (e: any) => {
    e.stopPropagation();
    props.onSelectOrder && props.onSelectOrder();
  };

  return (
    <Box bg={"white"} mb={4} shadow={"xs"} _hover={{ shadow: "md" }} pb={0} borderRadius={"xl"} onClick={handleClick} borderWidth={1} borderColor={"gray.200"}>
      <Box p={4}>
        <Flex justify={"space-between"} h={"full"}>
          <Box>
            <Flex align={"center"}>
              <Text fontWeight={"semibold"} fontSize={"sm"} color={"gray.800"}>
                {getValue("orderId")}
              </Text>
              {/* {props?.onSelectOrder && (
                <Flex align={"center"} bg={"red.100"} className="shrink-animation" ml={2} borderRadius={"sm"} h={5} px={0} onClick={handleSelect}>
                  <IconButton aria-label="Re-create" color={"red"} size={"xs"} variant={"link"} borderRadius={"full"} icon={<Icon icon={"lucide:copy-plus"} fontSize={14} />} />
                  <Text mx={2} ml={-4} fontSize={"xs"} color={"red"} fontWeight={"semibold"} whiteSpace={"nowrap"}>
                    Re-Create
                  </Text>
                </Flex>
              )} */}
              {props?.onSelectOrder && getValue("bookingType") !== "LEAD" && (
                <Flex align={"center"} bg={"red.100"} ml={2} borderRadius={"sm"} h={5} px={0} onClick={handleSelect}>
                  <IconButton aria-label="Re-create" color={"red"} size={"xs"} variant={"link"} borderRadius={"full"} icon={<Icon icon={"lucide:copy-plus"} fontSize={14} />} />
                  <Text mx={2} ml={-0.5} fontSize={"xs"} color={"red"} fontWeight={"semibold"} whiteSpace={"nowrap"}>
                    Re-Create
                  </Text>
                </Flex>
              )}
              {/* {props.isCrmMode && (
                <Box bg={selectedCard?.bg} px={3} ml={2} fontSize={"xs"} borderRadius={"full"} letterSpacing={"wide"} py={1} h={6}>
                  <Text color={selectedCard?.textColor} fontWeight={"900"}>
                    {removeUnderscore(selectedCard?.text || "")}
                  </Text>
                </Box>
              )} */}
            </Flex>

            <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.600"}>
              {getValue("requestedFor.name")}
            </Text>
          </Box>
          <Flex align={"start"}>
            <Box bg={selectedCard?.bg} px={3} fontSize={"xs"} borderRadius={"full"} letterSpacing={"wide"} py={1} h={6}>
              <Text color={selectedCard?.textColor} fontWeight={"900"}>
                {removeUnderscore(selectedCard?.text || "")}
              </Text>
            </Box>
            {/* <MoreOption /> */}
            {/* {props?.onSelectOrder && (
              <IconButton
                onClick={handleSelect}
                aria-label="More server options"
                icon={<Icon icon={"iconamoon:menu-kebab-vertical-bold"} color="black" />}
                variant="ghost"
                size={"xs"}
                p={0}
                // bg={"transparent"}
                fontSize={18}
                mr={-2}
              />
            )} */}
          </Flex>
        </Flex>
      </Box>
      <Divider />
      {
        <>
          <Flex w={"full"} justify={"space-between"} bg={"orange.50"} px={4} py={2}>
            <Box fontSize={"xs"} letterSpacing={"normal"}>
              <Flex align={"center"} color={"orange.500"}>
                <Icon icon={"uil:ambulance"} fontSize={18} fontWeight={800} opacity={1} />
                <Text fontWeight={"semibold"} fontSize={"sm"} ml={2} color={"orange.500"}>
                  {subtype ? ambulanceMapper[subtype] : "Not Selected Yet"}
                </Text>
              </Flex>
            </Box>
            {/* <Tag size={"sm"} >
              <TagLabel>{removeUnderscore(getValue("serviceType") || "")}</TagLabel>
            </Tag> */}
            <Box fontSize={"xs"} letterSpacing={"normal"}>
              <Flex align={"center"} color={"orange.500"}>
                <Text fontWeight={"semibold"} fontSize={"sm"} ml={2} color={"orange.500"} textTransform={"capitalize"}>
                  {getProperty(SERVICE_TYPE_MAP, getValue("serviceType") || "", "-")?.label}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Divider />
        </>
      }
      <Box px={4} py={2}>
        <Flex justify={"space-between"} align={"center"}>
          <Box>
            <Flex align={"center"}>
              <Icon icon={"tabler:clock"} fontSize={18} fontWeight={600} opacity={0.6} />
              <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.600"} ml={2}>
                {`${dayjs(new Date(getValue("createdAt") || "")).format("DD MMM YYYY  hh:mm A")} `}
              </Text>
            </Flex>
          </Box>
          <Box fontSize={"xs"} borderRadius={"full"} letterSpacing={"normal"} h={6}>
            <Flex align={"center"}>
              {/* <Icon icon={"tabler:currency-rupee"} fontSize={18} fontWeight={600} /> */}
              <Text fontWeight={"semibold"} fontSize={"md"} color={"gray.800"} ml={0}>
                {convertToCurrency(convertPrice(getValue("totalFare")))}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>

      {!isInvalidString(getValue("feedback.rating")) && <Rating score={getValue("feedback.rating") || 0} />}
      <Box px={4} py={2}>
        <Flex justify={"space-between"} align={"center"}>
          <Box>
            {!props?.isCrmMode ? (
              <Flex align={"center"}>
                <Icon icon={"lucide:user"} fontSize={18} fontWeight={600} opacity={0.6} />
                <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.600"} ml={2}>
                  {handleEmptyData(getValue("generatedBy"))}
                </Text>
              </Flex>
            ) : (
              <Stack>
                <Flex align={"center"}>
                  <Icon icon={"ic:baseline-window"} fontSize={18} fontWeight={600} opacity={0.6} />
                  <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.600"} ml={2}>
                    {handleEmptyData(getValue("attributedTo"))}
                  </Text>
                </Flex>
                <Flex align={"center"}>
                  <Icon icon={"ph:bank-bold"} fontSize={18} fontWeight={600} opacity={0.6} />
                  <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.600"} ml={2}>
                    {handleEmptyData(getValue("sourceName"))}
                  </Text>
                </Flex>
              </Stack>
            )}
          </Box>
        </Flex>
        <WaypointRender pickup={getValue("waypoints[0].address.location")} drop={getValue("waypoints[1].address.location")} />
      </Box>
      {/* <Box bg={"purple.100"} px={2} py={2} borderBottomRadius={"md"}>
        <Button size={"xs"} borderRadius={"full"}>
          Re-Create
        </Button>
      </Box> */}
    </Box>
  );
};

export default CrmCard;

export const WaypointRender = memo(({ pickup, drop, colorScheme = "blue" }: { pickup?: string; drop?: string; colorScheme?: string }) => {
  const waypoint = [
    {
      title: "Pickup Location",
      description: pickup,
    },
    {
      title: "Drop Location",
      description: drop,
    },
  ];

  if (!pickup && !drop) return <></>;

  return (
    <Stepper size="sm" index={4} orientation="vertical" mt={5} minH={100} colorScheme={colorScheme}>
      {waypoint.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus complete={<Icon icon="ph:map-pin-fill" />} active={<Icon icon="ph:map-pin-fill" />} />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>
              <Text fontSize={"sm"} fontWeight={"semibold"} color={"gray.700"} textAlign={"start"}>
                {step.title}
              </Text>
            </StepTitle>
            <StepDescription>
              <Text fontSize={"sm"} fontWeight={"normal"} w={"75vw"} textTransform={"lowercase"} textAlign={"start"}>
                {truncateString(step.description || "", 80)}
              </Text>
            </StepDescription>
          </Box>

          <StepSeparator style={{ marginTop: 6 }} />
        </Step>
      ))}
    </Stepper>
  );
});
